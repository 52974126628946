<!--具体解决方案-->
<template>
  <div class="solutionDetails-container">
    <new-cloude-header/>
    <div class="solutionDetails-content">
      <div class="solution-header">
        <div class="solution-title">
          <p class="large-title" v-html="solutionDetail.title"/>
          <p class="normal-p" v-html="solutionDetail.briefIntroduction" v-if="solutionDetail.briefIntroduction"/>
        </div>
      </div>
    </div>
    <!--行业痛点-->
    <div class="pain-spot">
      <div class="pain-content">
        <div class="solution--title">
          <p class="title" v-html="'行业痛点'"/>
          <p class="tips" v-if="painPoints.introduce" v-html="painPoints.introduce"/>
        </div>
        <el-row :gutter="20" style="margin-top: 20px;">
          <template v-if="painPoints.children.length>0">
            <template v-for="item of painPoints.children">
              <el-col :span="spanCount">
                <div class="spot-col" ref="painSpot" :style="'min-height:'+colHeight+'px;' ">
                  <p class="col-title" v-if="item.title!=''" v-html="item.title"/>
                  <p class="col-introduce" v-if="item.introduce!=''" v-html="item.introduce"/>
                  <template v-if="item.list">
                    <template v-for="li of item.list">
                      <ul>
                        <li class="list-style-type" v-html="li.introduce"/>
                      </ul>
                    </template>
                  </template>
                </div>
              </el-col>
            </template>
          </template>
        </el-row>
      </div>
    </div>
    <!--方案优势-->
    <div class="advantage">
      <div class="advantage-content">
        <div class="solution--title">
          <p class="title">方案优势</p>
          <p class="tips" v-if="advantage.introduce" v-html="advantage.introduce"/>
        </div>
        <el-row :gutter="20" style="margin-top: 20px;">
          <template v-if="advantage.children.length>0">
            <template v-for="item of advantage.children">
              <el-col :span="colCount">
                <div class="spot-col" ref="advantageSpot" :style="'min-height:'+colHeight2+'px;' ">
                  <p class="col-title" v-if="item.title!=''" v-html="item.title"/>
                  <p class="col-introduce" v-if="item.introduce!=''" v-html="item.introduce"/>
                  <template v-if="item.list">
                    <template v-for="li of item.list">
                      <li class="ii-introduce" v-html="li.introduce"/>
                    </template>
                  </template>
                </div>
              </el-col>
            </template>
          </template>
        </el-row>
      </div>
    </div>
    <!--方案描述-->
    <div class="scheme-description">
      <div class="scheme-content">
        <div class="solution--title">
          <p class="title" v-html="'方案描述'"/>
        </div>
        <div class="scheme-right-img" :style="'background: url('+require('@/assets/img/solution/组1548.png')+') no-repeat'"/>
        <div class="scheme-introduce">
          <div class="introduce-content">
            <p v-if="schemeDescription.introduce" class="introduce-title" v-html="schemeDescription.introduce" ref="scheme"/>
            <div class="scheme-ul" :style="'max-height:'+ulHeight+'px;'">
              <template v-if="schemeDescription.list.length>0">
                <template v-for="scheme of schemeDescription.list">
                  <ul v-if="scheme.title!=''">
                    <li class="list-style-type">
                      {{scheme.title}}
                      <ul>
                        <li class="none-list-style" v-html="scheme.introduce"/>
                      </ul>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="list-style-type" v-html="scheme.introduce"/>
                  </ul>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--功能架构/技术架构/业务架构-->
    <div class="architecture">
      <div class="architecture-content">
        <div class="solution--title">
          <p class="title" v-html="businessArchitecture.name"/>
        </div>
        <div class="architecture--content">
          <div class="architecture-left">
            <p v-if="businessArchitecture.introduce" class="introduce-title" v-html="businessArchitecture.introduce" ref="architecture"/>
            <div class="architecture-ul" :style="'max-height:'+ulHeight2+'px;'">
              <template v-if="businessArchitecture.list.length>0">
                <template v-for="architecture of businessArchitecture.list">
                  <ul v-if="architecture.title!=''">
                    <li class="list-style-type">
                      {{architecture.title}}
                      <ul>
                        <li class="none-list-style" v-html="architecture.introduce"/>
                      </ul>
                    </li>
                  </ul>
                  <ul v-else>
                    <li class="list-style-type" v-html="architecture.introduce"/>
                  </ul>
                </template>
              </template>
            </div>
          </div>
          <div class="architecture-right">
            <div class="architecture-img" @click="seePdf(require('@/assets/img/solution/'+businessArchitecture.img))" :style="'background: url('+require('@/assets/img/solution/'+businessArchitecture.img)+') no-repeat'" v-if="businessArchitecture.img"/>
          </div>
        </div>
      </div>
    </div>
    <!--客户案例-->
    <case :customerStories="[solutionDetail.customerStories]" :id="id" :industryId="industryId"/>
    <new-cloude-footer/>
    <el-dialog :visible.sync="dialogImage" title="图片预览" width="75%">
      <div style="display: flex;justify-content: center;align-items: center;">
        <el-image :src="dialogImageUrl" style="width: 75%;height: auto"/>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import solution from '@/static/solution.json'
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import Case from "@/components/case/case";
export default {
  name: "solutionDetails",
  components: {Case, NewCloudeFooter, NewCloudeHeader},
  data() {
    return {
      id: '',
      industryId: '',
      solutionDetail: {},
      spanCount: 0,
      painPoints: [],
      colHeight: 0,
      colHeight2: 0,
      advantage: {},
      colCount: 0,
      schemeDescription:{},
      businessArchitecture:{},
      ulHeight:0,
      ulHeight2:0,
      dialogImage:false,
      dialogImageUrl:'',
    }
  },
  mounted() {
    for (let ref of this.$refs.painSpot) {
      if (ref.clientHeight > this.colHeight) {
        this.colHeight = ref.clientHeight-10;
      }
    }
    for (let ref of this.$refs.advantageSpot) {
      if (ref.clientHeight > this.colHeight2) {
        this.colHeight2 = ref.clientHeight-10;
      }
    }
    if (this.schemeDescription.introduce){
      this.ulHeight=245-this.$refs.scheme.clientHeight;
    }else{
      this.ulHeight=270;
    }
    if (this.businessArchitecture.introduce){
      this.ulHeight2=370-this.$refs.architecture.clientHeight;
    }else{
      this.ulHeight2=403;
    }
  },
  beforeMount() {
    this.id = this.$route.query.id;
    this.industryId = this.$route.query.industryId;
    let reg = new RegExp('\n', 'g')//g代表全部
    for (let a of solution) {
      if (this.industryId == a.industryId) {
        for (let b of a.children) {
          if (b.id == this.id) {
            this.solutionDetail = b;
            if (this.solutionDetail.briefIntroduction.indexOf('\n') > -1) {
              this.solutionDetail.briefIntroduction = this.solutionDetail.briefIntroduction.replace(reg, '<br/>');
            }
            this.painPoints = this.solutionDetail.painPoints;
            this.advantage = this.solutionDetail.advantagesPlan;
            this.schemeDescription = this.solutionDetail.schemeDescription;
            this.businessArchitecture = this.solutionDetail.businessArchitecture;
            for (let children of this.painPoints.children) {
              if (children.introduce.indexOf('\n') > -1) {
                children.introduce = children.introduce.replace(reg, '<br/>');
              }
            }
            for (let children of this.advantage.children) {
              if (children.introduce.indexOf('\n') > -1) {
                children.introduce = children.introduce.replace(reg, '<br/>');
              }
            }
            for (let children of this.businessArchitecture.list) {
              if (children.introduce.indexOf('\n') > -1) {
                children.introduce = children.introduce.replace(reg, '<br/>');
              }
            }
            for (let children of this.schemeDescription.list) {
              if (children.introduce.indexOf('\n') > -1) {
                children.introduce = children.introduce.replace(reg, '<br/>');
              }
            }
            this.spanCount = this.painPoints.children.length == 3 || this.painPoints.children.length == 6 || this.painPoints.children.length == 9 ? 8
                : this.painPoints.children.length == 4 || this.painPoints.children.length == 7 || this.painPoints.children.length == 8 ? 6 : this.painPoints.children.length == 1 ? 24 : 8;
            this.colCount = this.advantage.children.length == 3 || this.advantage.children.length == 6 || this.advantage.children.length == 9 ? 8
                : this.advantage.children.length == 4 || this.advantage.children.length == 7 || this.advantage.children.length == 8 ? 6 : this.advantage.children.length == 1 ? 24 : 8;
          }
        }
      }
    }
  },
  methods:{
    seePdf(url){
      this.dialogImageUrl=url;
      this.dialogImage=true;
    }
  }
}
</script>
<style scoped lang="scss">
.solutionDetails-container {
  .solution-header {
    height: 400px;
    background: url("~@/assets/img/solution/solutionHeaderBackground.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .solution-title {
      width: 1200px;
      margin: auto;
      margin-top: 120px;
      padding-left: 80px;

      .large-title {
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        margin-block-end: 20px;
        margin-block-start: 0px;
        font-family: 'SourceHanSansCN';
      }

      .normal-p {
        font-size: 16px;
        color: #FFFFFF;
        margin-block-end: 0px;
        margin-block-start: 0px;
        font-family: "SourceHanSansCN";
        width: 623px;
      }
    }
  }
  .solution--title {
    text-align: center;
    .title {
      font-size: 30px;
      font-family: 'SourceHanSansCN';
      color: #333333;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-weight: 500;
    }
    .tips {
      font-family: 'SourceHanSansCN';
      color: #666666;
      font-size: 18px;
      margin-block-start: 10px;
      margin-block-end: 0px;
    }
  }
  .pain-spot {
    background: #FFFFFF;

    .pain-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .spot-col {
        padding: 30px 20px 10px;
        border: 1px solid #C6D6E4;
        margin-top: 20px;

        .col-title {
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
        }

        .col-introduce {
          font-size: 16px;
          font-family: 'SourceHanSansCN';
          color: #666666;
          margin-block-start: 0px;
          margin-block-end: 0px;
        }

        ::marker {
          color: #2FE4BD;
          unicode-bidi: isolate;
          font-variant-numeric: stacked-fractions;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;
          font-size: xx-small;
        }
        ul {
          margin-block-start: 0px;
          margin-block-end: 10px;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 15px;
          font-size: 16px;
        }
        .list-style-type{
          list-style-type: "◆";
          padding-left: 10px;
          color: #666666;
          font-family: 'SourceHanSansCN';
        }
      }
    }
  }
  .advantage {
    background: #F5FAFE;
    .advantage-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .spot-col {
        padding: 30px 20px 10px;
        margin-top: 20px;
        background: #FFFFFF;
        .col-title {
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
        }

        .col-introduce {
          font-size: 16px;
          font-family: 'SourceHanSansCN';
          color: #666666;
          margin-block-start: 0px;
          margin-block-end: 0px;
        }

        ::marker {
          color: #2FE4BD;
          unicode-bidi: isolate;
          font-variant-numeric: stacked-fractions;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;
        }

        .ii-introduce {
          color: #666666;
          font-size: 16px;
          font-family: 'SourceHanSansCN';
          line-height: 24px;
          margin-bottom: 5px;
        }
      }
    }
  }
  .scheme-description{
    background: #00083A;
    height: 588px;
    .scheme-content{
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .title{
        color: #FFFFFF;
      }
      .scheme-right-img{
        height: 400px;
        width: 820px;
        background-size: 100% 100% !important;
        float: right;
        margin-top: 40px;
      }
      .scheme-introduce{
        height: 255px;
        width: 580px;
        background: rgba(255, 255, 255, 0.8);
        z-index: 999;
        position: absolute;
        margin-top: 70px;
        padding: 30px 0px 30px 20px;
        .introduce-title{
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
          line-height: 24px;
          padding-right: 20px;
        }
      }
    }
    ul {
      margin-block-start: 0px;
      margin-block-end: 20px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 13px;
    }
    .none-list-style{
      list-style-type: none;
      margin-top: 5px;
    }
    .list-style-type{
      list-style-type: "◆";
      padding-left: 10px;
      color: #333333;
      font-family: 'SourceHanSansCN';
      ul {
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        font-size: 14px;
      }
    }
    .scheme-ul{
      overflow-y: auto;
      padding-right: 20px;
    }
    .scheme-ul::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px;
      height: 1px;
    }
    .scheme-ul::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.8);
      background: #C6D6E4;
    }
    .scheme-ul::-webkit-scrollbar-track {
      border-radius: 10px;
      background: center;
      box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.8);
    }
    ::marker {
      color: #2FE4BD;
      unicode-bidi: isolate;
      font-variant-numeric: stacked-fractions;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
      font-size: xx-small;
    }
  }
  .architecture{
    background: #F5FAFE;
    height: 580px;
    .architecture-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .title {
        color: #333333;
        text-align: left;
      }
      .architecture--content{
        display: flex;
        justify-content: space-between;
        .architecture-left{
          width: 49%;
          margin-top: 30px;
          .introduce-title{
            font-family: 'SourceHanSansCN';
            font-size: 18px;
            color: #333333;
            margin-block-start: 0px;
            margin-block-end: 20px;
            line-height: 24px;
          }
          ul {
            margin-block-start: 0px;
            margin-block-end: 20px;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 13px;
          }
          .none-list-style{
            list-style-type: none;
            margin-top: 5px;
          }
          .list-style-type{
            list-style-type: "◆";
            padding-left: 10px;
            color: #333333;
            font-family: 'SourceHanSansCN';
            ul {
              margin-block-start: 0px;
              margin-block-end: 0px;
              margin-inline-start: 0px;
              margin-inline-end: 0px;
              padding-inline-start: 0px;
              font-size: 14px;
            }
          }
          .architecture-ul{
            overflow-y: auto;
            padding-right: 10px;
          }
          .architecture-ul::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 8px;
            height: 1px;
          }
          .architecture-ul::-webkit-scrollbar-thumb {
            border-radius: 20px;
            box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.8);
            background: #C6D6E4;
          }
          .architecture-ul::-webkit-scrollbar-track {
            border-radius: 10px;
            background: center;
            box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.8);
          }
          ::marker {
            color: #2FE4BD;
            unicode-bidi: isolate;
            font-variant-numeric: stacked-fractions;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
            font-size: xx-small;
          }
        }
        .architecture-right{
          width: 50%;
          margin-top: 30px;
          .architecture-img{
            height: 400px;
            background-size: 100% !important;
            border: 1px solid #C6D6E4;
            background-position: center !important;
          }
        }
      }
    }
  }
}
</style>
