<template>
  <div class="case-container">
    <div class="case-title">
      <p class="title">客户案例</p>
      <p class="case-tips">我们致力于通过专业的沟通、创新的理念、优质的服务专注赢得每一位客户的信任和口碑</p>
    </div>
    <div class="case-content">
      <div class="case-left">
        <div class="case--content">
          <p class="case--title">{{caseInfo.title}}</p>
          <p class="content" v-html="caseInfo.customerStories.enterpriseIntroduction"/>
          <el-link :underline="false" @click="caseDetails()">更多详情<i class="el-icon-arrow-right el-icon--right"/></el-link>
        </div>
      </div>
      <div class="case-right" :style="'background: url('+require('@/assets/img/solution/'+caseInfo.customerStories.image)+') no-repeat'"/>
    </div>
  </div>
</template>
<script>
import solution from '@/static/solution.json'
export default {
  name: "case",
  data(){
    return{
      caseInfo:{},
    }
  },
  props:{
    customerStories:{
      type:Array,
      default:[]
    },
    id:{
      type:String,
      default: ''
    },
    industryId:{
      type:String,
      default:''
    }
  },
  methods:{
    /*客户案例详情*/
    caseDetails(){
      this.$router.push({
        path:'/caseDetails',
        query:{
          id:this.id,
          industryId:this.industryId
        }
      })
    }
  },
  mounted() {
    for (let a of solution){
      if (a.industryId==this.industryId){
        if (a.children.length>0){
          for (let b of a.children){
            if (b.id==this.id){
              this.caseInfo=b;
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.case-container >>> .el-link.el-link--default {
  color: #ffffff;
  font-size: 14px;
  font-family: 'SourceHanSansCN';
}
.case-container >>> .el-icon-arrow-right{
  margin-left: 20px;
  font-weight: bold;
}
</style>
<style scoped lang="scss">
.case-container {
  background: #FFFFFF;
  padding: 60px 0px 0px;
  .case-title {
    text-align: center;

    .title {
      font-size: 30px;
      font-family: 'SourceHanSansCN';
      color: #333333;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-weight: 500;
    }

    .case-tips {
      font-family: 'SourceHanSansCN';
      color: #666666;
      font-size: 18px;
      margin-block-start: 10px;
      margin-block-end: 40px;
    }
  }
  .case-content{
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    .case-left{
      width: 50%;
      height: 360px;
      background: linear-gradient(90deg, rgba(107,159,255,0.99), rgba(25,53,222,0.99));
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .case--content{
        width: 560px;
        float: right;
        padding-right: 40px;
        .case--title{
          font-size: 24px;
          color: #FFFFFF;
          font-family: "SourceHanSansCN";
          margin-block-start: -20px;
          margin-block-end: 20px;
        }
        .content{
          font-family: 'SourceHanSansCN';
          font-size: 16px;
          color: #FFFFFF;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          margin-block-end: 60px;
        }
      }
    }
    .case-right{
      width: 50%;
      height: 360px;
      background-size: cover !important;
    }
  }
}
</style>
